import { useState } from "react";
import About from './About';
import {T} from './PopUpJK';

const SelectDms = ({setDms, defaultVal, restartGame}) => {
  const [about, setAbout] = useState(false);
  const barStyle = 
    {
      width:'500px',
      display:'flex', 
      justifyContent:'center', 
      backgroundColor: 'rgb(237, 200, 79',
      borderRadius:'5px',
    };
    return (
      <div style={barStyle} >
      < T r={<label htmlFor="dms-select" >Board Size: &nbsp;</label> } s='number of rows/colums' />
      <select name="dms" value={defaultVal} id="dms-select" onChange={e => setDms(Number(e.target.value))}>
          <option value="3">3 X 3</option>
          <option value="4">4 X 4</option>
          <option value="5">5 X 5</option>
          <option value="6">6 X 6</option>
          <option value="7">7 X7</option>
          <option value="8">8 X 8</option>
      </select>  
      &nbsp;&nbsp;
      <T r={<button className='newButton' onClick={restartGame}>New</button>} s='New game' />
      &nbsp;&nbsp;
      <T r={<button className='aboutButton' onClick={()=>setAbout(!about)}>About</button>} s='About' />
      <About about={about} setAbout={setAbout}  />
    </div>
    );
}

export default SelectDms;
