import React from 'react'

function WinnerScreen({ restartGame, playerWon }) {
    const restartStyle = 
      {
        backgroundColor:'lightblue', 
        color:'black', 
        border:'0px',
        borderRadius: '3px'
      }
    return (
        <div className='winner'>
            <h2 className='congo'>
                { playerWon === "🟡⚫" ? 'Oops! Its a draw' : 'Congratulation!' }
            </h2>
            <h3 className='player'>
                { playerWon === "🟡⚫" ? '' : `${playerWon} Won the Game.` }
            </h3>
            <button style={ restartStyle} onClick={restartGame}>
                Restart
            </button>
        </div>
    )
}

export default WinnerScreen
