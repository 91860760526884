import { useState, useEffect, useMemo } from "react";
import './App.css';
import { MakeInitPatterns, MakeCheckPatterns } from './Patterns'
import InitBoard from './InitBoard';
import WinnerScreen from "./WinnerScreen";
import SelectDms from './selectDms';
import {T} from './PopUpJK';

//game sounds initialize
const clickSound = new Audio('/click.mp3')
const winnerSound = new Audio('/win.wav')
const restartSound = new Audio('/restart.wav')

//game sound functions
const clickPlaySound = () => {
  clickSound.play()
}
const gameWinnerSound = () => {
  winnerSound.play()
}
const gameRestartSound = () => {
  restartSound.play()
}

const DMS =  Number(localStorage.getItem('DMS_VAL') ?? 4 );

function App() {

  //box index
  const [dms, setDms] = useState(DMS);
  const [board, setBoard] = useState([]);
  const [game, setGame] = useState({turn:'', winner: 'none', turnNo: 0});
  
  const IP = useMemo(() => MakeInitPatterns(dms),[dms]);
  const CP = useMemo(() => MakeCheckPatterns(dms),[dms]);

  useEffect(() => {
    setBoard(((n) => new Array(n * n).fill(""))(dms));
    setGame({turn:"🟡" , winner: 'none', turnNo: 0});
  }, [dms]);

  const checkWin = () => {
    CP.forEach(pattern => {
      const vals = [];
      pattern.forEach((i) => {
        vals.push(board[i]);
      });
      
      if (vals.every(val => val === "🟡")) {
        gameWinnerSound();
        setGame({...game, winner: "🟡"});
      } else if(vals.every(val => val === "⚫")) {
        gameWinnerSound();
        setGame({...game, winner: "⚫"});
      } else if (game.turnNo === dms * dms)  {
        gameWinnerSound();
        setGame({ ...game, winner: "🟡⚫" });
      }  
    });
  };

  useEffect(() => {
    checkWin();
  },[board]);

  //handling click on box
  const handleClick = (square) => {
    if(board[square] === "") {
      clickPlaySound();
      setBoard(board.map((val, idx) => idx === square ? game.turn : val));
      setGame({...game, turn:(game.turn === "🟡" ? "⚫" : "🟡"), turnNo: game.turnNo + 1});
    }    
  }

  //restart
  const restartGame = () => {
    localStorage.setItem('DMS_VAL', dms);
    window.location.reload();
    gameRestartSound();
  };

  const boardSize ={ width: `${80 * dms}px`, height: `${80 * dms}px`};

  return (
    <>
    <div style={{height:'20px', backgroundColor: '#5e2db3' }}></div>
    <div className='SelDms'>
      < SelectDms setDms={setDms} defaultVal={dms} restartGame={restartGame} />
      <h1 className='title' style={{textAlign:'center'}}>Let's Play Tic Tac T🟡e<br /> <T r={<span >{game.turn}</span>} s={`${game.turn}'s turn`} /></h1>
      <div className='board' style={boardSize}>   
        <InitBoard initPatterns={IP} handleClick={handleClick} board={board} dms={dms} />      
      </div>
      {
        game.winner !== 'none' && 
        <WinnerScreen restartGame={restartGame} playerWon={game.winner} /> 
      }
    </div>
    <div className="App">   
    </div>
    </>
  );
}

export default App;

