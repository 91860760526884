export const Patterns = [
    [0, 1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11],
    [12, 13, 14, 15],
    [0, 4, 8, 12],
    [1, 5, 9, 13],
    [2, 6, 10, 14],
    [3, 7, 11, 15],
    [0, 5, 10, 15],
    [3, 6, 9, 12],
  ];
  export const InitPatterns = [
    [0, 1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11],
    [12, 13, 14, 15]
  ];

  export const MakeInitPatterns = (dms) => {
    if (dms < 3 || dms > 10) {
      console.log("dms < 3 || dms > 10");
      return;
    }
    let IP = [];
    for (let i = 0; i < dms; i++) {
      let p=[];
      for (let j = 0; j < dms; j++) {
          p.push(i * dms + j);
      }
      IP.push(p);
    }
    return IP;
  }

  export const MakeCheckPatterns = (dms) => {
    if (dms < 3 || dms > 10) {
      console.log("dms < 3 || dms > 10");
      return;
    }
    let IP = [];
    for (let i = 0; i < dms; i++) {
      let p=[];
      for (let j = 0; j < dms; j++) {
          p.push(i * dms + j);
      }
      IP.push(p);
    }
    for (let i = 0; i < dms; i++) {
      let p=[];
      for (let j = 0; j < dms; j++) {
          p.push(j * dms + i);
      }
      IP.push(p);
    }

    let p=[];
    for (let i = 0; i < dms; i++) {
      p.push(i * dms + i);   
    }
    IP.push(p);

    let q=[];
    for (let i = 0; i < dms; i++) {
      q.push(i * dms + (dms - i - 1));   
    }
    IP.push(q);

    return IP;
  }