const aboutText = 
<>
This is a TicTacTo game. <br />
1. The basic structure is the same as that from React web site(https://react.dev/)<br />
2. The color of the board, shape of game pieces are changed and the internal <br />
  implementation is streamlined. Sounds are added.<br />
3. The size of the board can be adjusted from 3 X 3 to 8 X 8.<br />
4. You can start as soon as the board is rendered. And can restart any time during the game.<br />
5. When you resize the board, a new game is started.
</>;

export default aboutText;