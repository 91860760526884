import React from "react";
import "../App.css";
function Square({ val, chooseSquare=null }) {
  const s = { width: '80px',height: '80px' };
  return (
    <div className="square" style={s} onClick={chooseSquare}>
        {val}
    </div>
  );
}

export default Square;
