import Square from './Component/Square';

const InitBoard = ({initPatterns, handleClick, board, dms}) => {
    //console.log(initPatterns);
    //console.log(board);
    const r = { width: `${80 * dms}px`,height: '80px' };
    return (
        initPatterns.map((pattern) => 
            <div className='row' style={r} key={pattern[0]}>
            {
                pattern.map((p) =>
                    <Square
                        chooseSquare={() => { handleClick(p) }}
                        val={board[p]}
                        key={p}
                    />
                )
            }
            </div>
        )

    );
}
export default InitBoard;
